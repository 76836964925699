<router>
{
    name: 'Dorset Controls Central Server',
}
</router>
<template lang="pug">
v-container
    v-row
        v-col
            Permissions

</template>
<script>

import Permissions from '@/components/dccs/permissions.vue';
export default {
    meta: {
        role: 'admin'
    },
    components: {
        Permissions
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
    }
};
</script>

<style scoped>
</style>
